import React from 'react';
import styled from 'styled-components';
import {Button} from 'react-bootstrap';
import {Link} from 'gatsby';

const BaseButton = styled(Button)`
  font-size: 1.2rem;
  @media (min-width: 475px) {
    height: 100%;
  }

  @media (max-width: 475px), @supports (height: -webkit-fit-content), @supports (height: -moz-fit-content){
    height: -webkit-fit-content;
    height: -moz-fit-content;
    width: 100%;
  }

  @media (max-width: 475px), not @supports (height: -webkit-fit-content), not @supports (height: -moz-fit-content){
    height: unset;
    width: 100%;
  }

  display: inline-block;
  font-family: 'montserrat', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  width: intrinsic;           /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content;    /* Firefox/Gecko */
  width: -webkit-max-content;
  min-width: 130px;
  max-width: 83.5vw;
  text-align: center;
  padding: 10px 20px;
  margin: 0 auto 0;
  border-width: 3px;
  border-style: solid;
  
  &.large {
    @media (min-width: 475px) {
      font-size: 48px;
    }
    @media (max-width: 475px) {
      font-size: 29px;
    }
  }
  
  &.small {
    font-size: 1rem;
    border-width: 2px;
    padding: 5px 10px;
    font-weight: 600;
  }
`
const StandardButton = styled(BaseButton)`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.base};
  border-radius: 0px;
  border-color: ${props => props.theme.colors.base};
  &:hover {
    border-radius: 0px;
    color: ${props => props.theme.colors.base};
    background-color: ${props => props.theme.colors.highlight};
    border-color: ${props => props.theme.colors.highlight};
  }
`
const SolidWhiteButton = styled(BaseButton)`
  color: ${props => props.theme.colors.base};
  background-color: unset;
  border-radius: 2px;
  border-color: ${props => props.theme.colors.base};
  &:hover {
    border-radius: 2px;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.base};
    border-color: ${props => props.theme.colors.base};
  }
`

const WhiteWhiteButton = styled(BaseButton)`
  color: ${props => props.theme.colors.white};
  background-color: unset;
  border-radius: 2px;
	border-color: ${props => props.theme.colors.white};
  &:hover {
    border-radius: 2px;
    color: ${props => props.theme.colors.base};
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.white};
  }
`

const ModalButton = styled(BaseButton)`
  color: ${props => props.theme.colors.base};
  background-color: ${props => props.theme.colors.white};
  border-radius: 2px;
	border-color: ${props => props.theme.colors.white};
  &:hover {
    border-radius: 2px;
    color: ${props => props.theme.colors.secondary};
    background-color: ${props => props.theme.colors.white};
    border-color: ${props => props.theme.colors.white};
  }
`

// Pontential Props:
// variant, filled (boolean)
const CTAButton = ({ variant, size, className, ...props }) => {
  let buttonClass;
  
  if (size) buttonClass = size + " ";
  if (className) buttonClass = buttonClass + className;
  
  switch (variant) {
    case 'white-white':
      return <WhiteWhiteButton className={buttonClass} {...props}>{props.children}</WhiteWhiteButton>
    case 'solid':
      return <StandardButton className={buttonClass} {...props}>{props.children}</StandardButton>
    case 'solid-white':
      return <SolidWhiteButton className={buttonClass} {...props}>{props.children}</SolidWhiteButton>
    case 'modal':
      return <ModalButton className={buttonClass} {...props}>{props.children}</ModalButton>
    default:
      return <></>
  }
}
export default CTAButton
